html, body
{
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

main {
  padding-top: 60px;
}

nav {
  background-color: white;
}

.navbar-special-mode {
  border-top: 1px solid black;
}

.form-control {
  width: 160px;
  height: 50px;
}

.brand-logo {
  margin-right: 5px;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;''
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

/******* GOOGLE BTN***** */

@import url(https://fonts.googleapis.com/css?family=Roboto:500);
.google-btn {
  width: 184px;
  height: 42px;
  background-color: #4285f4;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
}
.google-btn .google-icon-wrapper {
  position: absolute;
  margin-top: 1px;
  margin-left: 1px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: #fff;
}
.google-btn .google-icon {
  position: absolute;
  margin-top: 11px;
  margin-left: 11px;
  width: 18px;
  height: 18px;
}
.google-btn .btn-text {
  float: right;
  margin: 11px 11px 0 0;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: "Roboto";
}
.google-btn:hover {
  box-shadow: 0 0 6px #4285f4;
}
.google-btn:active {
  background: #1669F2;
}

/***********************/



/*.react-autosuggest__suggestions-list {*/
  /*margin: 0;*/
  /*padding: 0;*/
  /*list-style-type: none;*/
/*}*/

/*.react-autosuggest__suggestion {*/
  /*cursor: pointer;*/
  /*padding: 10px 20px;*/
/*}*/

/*.react-autosuggest__suggestion--highlighted {*/
  /*background-color: #ddd;*/
/*}*/

/*.react-autosuggest__container {*/
  /*position: relative;*/
/*}*/

/*LANDING*************************/
h1 {
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
}

header.masthead {
  position: relative;
  background-color: #343a40;
  background: no-repeat no-repeat -520px 0px scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 3rem;
  padding-bottom: 37rem;
}

header.masthead .overlay {
  position: absolute;
  background-color: #212529;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
}

.landing {
  position: relative;
  background: no-repeat no-repeat -450px 0px scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.landing .overlay {
  position: absolute;
  background-color: #212529;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
  color: white;
  z-index: -1;
}

@media (min-width: 768px) {
  .landing {
    background: no-repeat no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

header.masthead h1 {
  font-size: 2rem;
}

.full-input {
  width: 100%;
}

.react-autosuggest__container {
  width: 100%;
}

.nav-bar-form {
  width: 100%;
}

.hidden-submit {
  width: 0px;
  padding: 0px;
  margin: 0px;
}

@media (min-width: 768px) {
  header.masthead {
    padding-top: 3rem;
    padding-bottom: 37rem;
    background: no-repeat no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  header.masthead h1 {
    font-size: 3rem;
  }
}

.bottom-bar {
  padding-top: 0;
  padding-bottom: 0;
}

.main-content {
  padding-bottom: 70px;
  overflow-y: scroll;
}

.nav-bar-btn {
  margin: 3px 0;
  height: 50px;
  width: 50px;
}

.shopping-list {
  padding-top: 15px;
}

.user-lists {
  padding-top: 15px;
}

.user-list.active {
  background-color: #e3f2fd;
  border-color: #e3f2fd;
  color: #495057;
}

.shopping-items {
  height: 55px;
  font-size: medium;
  padding-top: 10px;
  overflow: hidden;
  -moz-transition: height 100ms linear;
  -webkit-transition: height 100ms linear;
  -o-transition: height 100ms linear;
  transition: height 100ms linear;
}

.history-items {
  font-size: medium;
  padding-top: 10px;
}

.history-items small {
  color: gray;
}

.item-text {
  padding-top: 5px;
}

.shopping-items.active {
  background-color: #e3f2fd;
  border-color: #e3f2fd;
  color: #495057;
  height: 100px;
  overflow: hidden;
  -moz-transition: height 100ms linear;
  -webkit-transition: height 100ms linear;
  -o-transition: height 100ms linear;
  transition: height 100ms linear;
}

.check-item-btn {
  color: green;
  background-color: transparent;
  border-color: transparent;
}

.check-item-icon {
  height: 30px;
  width: 30px;
}

.add-item-toolbar-icon {
  color: green;
  height: 25px;
  width: 25px;
}

.remove-item-icon {
  color: red;
  height: 25px;
  width: 25px;
}

.cancel-item-icon {
  color: red;
  height: 25px;
  width: 25px;
}

.accept-item-icon {
  color: green;
  height: 25px;
  width: 25px;
}

.add-more-icon {
  color: orange;
  height: 25px;
  width: 25px;
}

.unselect-item-icon {
  color: gray;
  height: 25px;
  width: 25px;
}

.user-list-shared-text {
  color: gray;
}

.share-list {
  float: right;
}

.add-btn {
  background-color: orange;
  border-color: orange;
  font-size: 20px;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.list-shared-notification {
  margin-top: 30px;
}

.item-history-bar {
  min-height: 72px;
}